import React from "react";
import ReactMarkdown from "react-markdown";
import { graphql } from "gatsby";

import Nav from "../Components/Nav";
import Footer from "../Components/Footer";
import SEO from "../Components/SEO"

import { Facebook, Instagram, Wikipedia, Youtube } from 'react-bootstrap-icons';

const GameLanding = ({ game }) => {
  return <div className="gameLanding">
  <img src={game.landingImage} alt={game.name} />
</div>;
} 

const GameLinks = ({ game }) => {
  const { urls } = game.gameDetails
  return <div className="gameLinks">
    {urls.googlePlay &&
      <a href={urls.googlePlay} target="_blank" rel="noreferrer noopener">
        <img src="https://i.imgur.com/TVttfis.png" alt={`Get ${game.name} on Google Play!`} />
      </a>
    }
    {urls.appStore &&
      <a href={urls.appStore} target="_blank" rel="noreferrer noopener">
        <img src="https://i.imgur.com/9jm0UJR.png" alt={`Get ${game.name} on App Store!`} />
      </a>
    }
  </div>;
}

const GameIntro = ({ game }) => {
  const { gameDetails } = game;
  const { intro } = gameDetails;
  console.log(game.social, game.social && game.social.facebook)
  return <section className="gameIntro">
    <div className="units">
      <img src={gameDetails.unitImage} alt={game.name} />
    </div>
    <div className="gameInfo">
      <div className="gameHeader">
        <img src={game.appIcon} alt={game.name}/>
        <div className="gameMeta">
          <h5 className="gameType">{game.category}</h5>
          <h1 className="gameName">{game.name}</h1>
        </div>
      </div>
      <div>
        <h2>{intro.title}</h2>
        <p>{intro.description}</p>
      </div>
      <GameLinks game={game} />
      <div className="social">
        {game.social && game.social.facebook &&
          <a href={game.social.facebook} target="_blank">
            <Facebook color="#1a1a1a" size={64} />
          </a>
        }
        {game.social && game.social.instagram &&
          <a href={game.social.instagram} target="_blank">
            <Instagram color="#1a1a1a" size={64} />
          </a>
        }
        {game.social && game.social.youtube &&
          <a href={game.social.youtube} target="_blank">
            <Youtube color="#1a1a1a" size={64} />
          </a>
        }
        {game.social && game.social.wiki &&
          <a href={game.social.wiki} target="_blank">
            <Wikipedia color="#1a1a1a" size={64} />
          </a>
        }
      </div>
    </div>
  </section>;
}

const GameFeatures = ({ game }) => {
  const { gameDetails } = game;
  return <section
    className="gameFeatures" 
    style={{
      backgroundImage: `url(${gameDetails.featureBackground})`,
      backgroundSize: "cover",
      backgroundPosition: "center"
    }}
  >
    <h1>About the Game</h1>
    <ReactMarkdown>
      {gameDetails.features}
    </ReactMarkdown>
  </section>;
}

const GameScreenshots = ({ game }) => {
  const { orientation, screenshots } = game.gameDetails
  return <section className="screenshotContainer"> 
    <div className={`screenshots ${orientation}`}>
      {screenshots.map((screenshot, i) => <img key={i} src={screenshot} alt={game.name} />)}
    </div>
  </section>;
}

const GameOutro = ({ game }) => {
  const { name, appIcon } = game;
  return <section className="gameOutro">
    <img src={appIcon} alt={name}/>
    <h1>{name}</h1>
    <GameLinks game={game} />
  </section>
}

const GameTemplate = ({ data }) => {
  const { gamesJson: game } = data;
  return <main className="gamePage">
    <SEO title={`${game.name} - Funovus`} description={game.description}/>
    <Nav />
    <GameIntro game={game} />
    <GameFeatures game={game} />
    <GameScreenshots game={game}/>
    <GameOutro game={game} />
    <Footer />
  </main>;
}

export default GameTemplate


export const pageQuery = graphql`
  query GameByPath($path: String!) {
    gamesJson( path: { eq: $path }) {
      name
      category
      appIcon
      landingImage
      featureImage
      description
      gameDetails {
        unitImage
        intro {
          title
          description
        }
        urls {
          googlePlay
          appStore
        }
        features
        featureBackground
        orientation
        screenshots
      }
      social {
        facebook
        instagram
        youtube
        wiki
      }
    }
  }
`
